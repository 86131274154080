import * as React from 'react';
// import img1 from '../media/00100lPORTRAIT_00100_BURST20190807194311598_COVER.jpg';
// import img2 from '../media/00100lPORTRAIT_00100_BURST20190808184239020_COVER.jpg';
// import img3 from '../media/IMG_20200125_082552.jpg';
// import img4 from '../media/IMG_20200724_210619.jpg';
// import img5 from '../media/IMG_20201104_165529.jpg';
import img6 from '../media/PXL_20210104_135807485.jpg';
import '../style/main.css';

// const images = [img1, img2, img3, img4, img5, img6];
// const offsets = [0];

export default () => {
  const [imageIndex, setImageIndex] = React.useState(5);

  return <div className="main">
    <i class="fas fa-angle-left" />
    <img src={img6} className="image" />
    <i class="fas fa-angle-right" />
  </div>;
};
